article, 
aside, 
details, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
main, 
menu, 
nav, 
section, 
summary                                   { display: block; }
body                                      { background-color: #ecf0f5; /* height: 1000px; */ font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" }


html,
body                                      { margin:0; padding:0; background-color: #222d32 !important; }
body:not(.layout-fixed) .main-sidebar     { height: inherit; min-height: 100%; position: absolute; top: 0; }

@media (min-width: 768px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 270px;
  }
}

a                                         { text-decoration:none; cursor:pointer; }
a:active,
a:hover                                   { color: #dd4b39; }
label                                     { font-weight:normal; padding-top:0.33em; }
h1                                        { font-size: 20px; }
h2                                        { font-size: 18px; }
h3                                        { font-size: 16px; }
h4                                        { font-size: 14px; margin:0; }
/* color: #8aa4af;  */
h5                                        { font-size: 12px; margin:0; }

.absolute                                 { position:absolute; }
.action-cell button                       { min-width: 36px; }
.action-cell .dropdown-menu               { min-width:60px !important; width: 75px !important; background-color: #333; }
.action-cell .dropdown-menu>li>a          { padding:3px 10px; color:#b8c7ce; font-size:12px; }
.action-cell .dropdown-menu>li>a>.glyphicon { padding:3px 0; color:#b8c7ce; font-size:10px; }
.action-cell .dropdown-menu>li>a:hover    { background-color:#222d32; color:#ffffff; }
.action-cell .dropdown-menu>li>a:hover>.glyphicon { background-color:#222d32; color:#ffffff; }

.action-icon					                    { text-align:center; flex:0 0 50px !important; }
.action-icon.rt-td		                    { cursor:pointer; }
.active-indicator                         { margin-right: .5em; }
.tis-alert                                { border-radius:0; border-bottom-left-radius:.33em; border-bottom-right-radius:.33em; border-top:none; padding: .5em 1em 10px; color: #FFF; 
  /* transform: translate(calc(50vw - 200px));  */
  /* margin-left: -200px; */
  /* width: 400px;
  left: 50vw;
  margin-left: -200px;
  position: absolute; */
  background-color: #0097C4; border-color:#3925D7; white-space: b;}
.tis-alert-container .tis-alert-close               { 
  position: absolute;
  right: -12px;
  top: -12px;
  border-radius: 20px; 
  font-size: 22px !important; 
  color: #DDD;
  cursor: pointer;
}
.tis-alert-container .tis-alert-close:hover         { color: #FFF; }
.success.tis-alert-close       { background-color: #4B8E00 !important;}
.info.tis-alert-close          { background-color: #0097C4 !important;}
.warn.tis-alert-close          { background-color: #DD9D39 !important;}
.warning.tis-alert-close       { background-color: #DD9D39 !important;}
.error.tis-alert-close         { background-color: #dd4b39 !important;}

.tis-alert-success                        { background-color: #4B8E00 !important; border-color:#25D739; }
.tis-alert-info                           { background-color: #0097C4 !important; border-color:#3925D7; }
.tis-alert-warn, .tis-alert-warning       { background-color: #DD9D39 !important; border-color:#D79225; }
.tis-alert-error                          { background-color: #dd4b39 !important; border-color:#d73925; }
.tis-alert-container                      { 
  position: fixed; 
  z-index: 99100;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  top: 48px;
}

.content-wrapper > .content.body          { position:relative; min-height: calc(100vh - 3em) !important; background-color: rgb(244, 246, 249); }
.bold                                     { font-weight:bold; }

.btn-icon                                 { position: relative; top: 1px; }
.center                                   { text-align:center; }
.clear                                    { clear:both; }
.clickable                                { cursor:pointer; }
.command-bar                              { margin:.25em 0; white-space:nowrap; position:absolute; right:1em; background-color:#ecf0f5; }
.command-bar a,
.command-bar button                       { margin-left: .75em; }
.command-bar-inline                       { margin: 0 1em 1em; }
.comand-bar-inline a,
.command-bar-inline button                { margin-left: .75em; }
.content                                  { position:relative; padding-bottom: 75px; }
.content .nav-container                   { position:fixed; width:calc(100% - 270px - 0em); z-index: 3000; padding: 0; margin:0; padding-right: .5em;}
.content nav                              { top:50px; left:230px; padding: .25em .5em; margin: 0; background-color: rgba(236, 240, 245); color:#444; border:1px solid #DDD; border-radius:0; }
.content nav.fullscreen                   { top:0px; left:0px;}
.control-label                            { font-size:16px; color:#8aa4af; padding-left:0; padding-top:0; padding-bottom:0; margin:0;}

.content-wrapper > .content               { padding: 0 0.25rem 2.5em !important; top: 3em !important; }

@media only screen and (max-width : 767px) {
  .content nav                              { top:100px; left:0px; }
}

.disabled                                 { background-color:#EEE; color:#999; }

.fg-aqua                                  { color:#00c0ef !important; }
.fg-green                                 { color:#00a65a !important; }
.fg-forest                                { color:#003947 !important; }
.fg-red                                   { color:#dd4b39 !important; }
.fg-yellow                                { color:#f39c12 !important; }
.fg-gray                                  { color:#d2d6de !important; }
.fg-purple                                { color:#663399 !important; }
.fixed .main-header                       { top: 0; right: 0; left: 0; }
.fixed .left-side, 
.fixed .main-header, 
.fixed .main-sidebar                      { position: fixed; }
.form-control                             { border-radius:.33em !important; }
.form-control-autocomplete,
.form-control-date,
.form-control-no-info                     { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; padding-right: 0 !important; }
.form-control-feedback                    { right: 15px; }

.hidden                                   { display:none; }
.info                                     { font-size:11px; color:#999;}
.loading                                  { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(255, 255, 255, 0.8); -webkit-transition:all .3s ease; transition:all .3s ease; z-index:99000; pointer-events:none; width:100%; height:100%;}
.loading > div                            { position:absolute; display:block; text-align:center; width:100%; top:250px; left:0; font-size:inherit; color:rgba(0, 0, 0, 0.6); -webkit-transform:translateY(-52%); transform:translateY(-52%); -webkit-transition:all .3s cubic-bezier(.25, .46, .45, .94); transition:all .3s cubic-bezier(.25, .46, .45, .94) }
.loading > div > img                      { position:relative; top: -1px; left:3px; }
.loading.active                           { opacity: 1; pointer-events: all; }
.loading.active > div                     { -webkit-transform: translateY(50%); transform: translateY(50%) }

@media only screen and (max-width : 767px) {
  .main-footer                              { padding:0.25em; min-height: 0px; }
}

.main-header                              { position: fixed !important; max-height: 100px; z-index: 400 !important; margin: 0 !important; border: none !important; height: 3em /*height: 46px*/; background-color: #d73925; }
.main-header .logo                        { transition: width .3s ease-in-out; display: block; float: left; font-size: 22px; text-align: center; width: 270px; font-family: Helvetica Neue,Helvetica,Arial,sans-serif; padding: 0 15px; font-weight: 300; overflow: hidden; background-color: #d73925; color: #fff; border-bottom: 0 solid transparent; }
.main-header .navbar                      { background-color: #d73925; }
.main-header .sidebar-toggle              { float: left; background-color: initial; height:30px; color: #FFF; }
.main-sidebar                             { background-color: #222d32; height: 100vh; overflow-y: hidden; position: fixed !important; top: 0; left: 0; padding-top: 50px; min-height: 100%; width: 270px !important; z-index: 10 !important; transition: transform .3s ease-in-out,width .3s ease-in-out; }

.m0                                       { margin:0 !important; }
.mb-0_5em                                 { margin-bottom: 0.5em !important; }
.mb-1em                                   { margin-bottom: 1em !important; }
.mb-2em                                   { margin-bottom: 2em !important; }
.mb-3em                                   { margin-bottom: 3em !important; }
.ml-0_5em                                 { margin-left: 0.5em !important; }
.ml-1em                                   { margin-left: 1em !important; }
.ml-2em                                   { margin-left: 2em !important; }
.ml-3em                                   { margin-left: 3em !important; }
.mr-0_5em                                 { margin-right: 0.5em !important; }
.mr-1em                                   { margin-right: 1em !important; }
.mr-2em                                   { margin-right: 2em !important; }
.mr-3em                                   { margin-right: 3em !important; }
.mr-50px                                  { margin-right: 50px !important; }
.mt0                                      { margin-top:0 !important; }
.mt-0_5em                                 { margin-top: 0.5em !important; }
.mt-1em                                   { margin-top: 1em !important; }
.mt-2em                                   { margin-top: 2em !important; }
.mt-3em                                   { margin-top: 3em !important; }
.mt-50px                                  { margin-top: 50px !important; }

.navbar-fixed-bottom                      { -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out !important; bottom: 0 !important; position: fixed !important; left: 0 !important; right: 0 !important; z-index: 10;}
.navbar-fixed-top                         { height: 50px; -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out; }
.navbar-static-top                        { z-index: 10; border-width: 0 0 1px; }

.overflow-visible                         { overflow:visible !important; }

.p0                                       { padding:0; }
.page-content                             { padding-top:0em; min-height:600px;}
.page-content-adjusted                    { padding-top:3.2em; }

.panel .panel-body                        { position:relative; }
.panel .panel-body .loading  > div        { top:40%; }
.panel-header                             { padding-left: 1em;}
.pb-1em                                   { padding-bottom: 1em; }
.pr-1em                                   { padding-right: 1em; }
.pt0                                      { padding-top:0 !important; }
.ptb-0_5em                                { padding-top: .5em; padding-bottom: .5em; }
.ptb-1em                                  { padding-top: 1em; padding-bottom: 1em; }

.relative                                 { position: relative; }

form .row                                 { padding:0.33em 0; min-height:3.17em; margin:0; }
.row                                      { margin:0; }
.row.row-odd                              { background-color: transparent; }
.row.row-even                             { background-color: rgba(144, 144, 144, 0.1); }

.shadow                                   { box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.sidebar-collapse .navbar-fixed-top       { left:0 !important; }
.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before { margin-left: -270px !important; }
.sidebar-collapse .content .nav-container { width:calc(100% - 0em); padding-right: 1em; }
.sidebar                                  { padding: 0 !important; }
.sidebar-menu li                          { width: 270px; position: relative; }
.sidebar-menu li .submenu-icon            { position: absolute; right: 10px; }
.sidebar-menu li.divider                  { border-bottom: 1px dashed #8aa4af; margin: .3em 1em; }
.sidebar-menu li .treeview-menu           { margin: 0 1px; background: #2c3b41; padding-left: .6em; }
.sidebar-menu li .treeview-menu li        { margin-left:10px; }
.sidebar-menu li a                        { color: #8aa4af; padding: 5px 2px 5px .5em; display: block; white-space: nowrap; line-height: 1.42857143; }
.sidebar-menu li a:hover,
.sidebar-menu li a:active                 { color: #fff; }
.sidebar-menu li a .MuiSvgIcon-root       { font-size: 18px; margin: 0 .4em .25em .25em; }
.sidebar-menu .help-button                { color: #8aa4af; padding: 5px 2px 5px .5em; display: block; white-space: nowrap; line-height: 1.42857143; text-transform: capitalize; font-size: inherit; }
.sidebar-menu .help-button .MuiButton-startIcon { display: unset; margin: unset; }
.sidebar-menu .help-button:hover          { color: #fff; }
.sidebar-menu .help-button .MuiSvgIcon-root       { font-size: 18px; margin: 0 .25em .25em; }
.sidebar-menu .MuiButtonBase-root                   { font-size: inherit !important; }
.sidebar-menu .MuiButtonBase-root.MuiButton-root    { padding: 5px 2px 5px .5em !important; }


.snapshot-mode-label                      { color: #FFF; position:fixed; top:102px; right:0; z-index:300; background-color:#f39c12; padding: 0.4em 1em; }
.snapshot-mode-label .glyphicon           { color: #FFF; }

.tis-commandbar button.MuiButtonBase-root.MuiButton-root { margin: .2em .2em !important; padding: 4px 6px 2px !important; min-width: 16px;}
.tis-breadcrumb-container                 { margin: 0 !important; }
.tis-breadcrumb                           { top: 0 !important; left: 0 !important; padding: .2em .2em !important; }
.tis-breadcrumb h1                        { font-size: 20px !important; margin: 0 !important; padding: 0 !important; vertical-align: bottom !important; }
.tis-breadcrumb .MuiSvgIcon-root          { font-size: 20px !important; margin: 0 !important; position: relative !important; top: -1px !important; }
.tis-breadcrumb .MuiButtonBase-root       { font-size: .85rem !important; }

.tis-page                                 { width: 100%; }

.unclickable                              { pointer-events:none; }

.visible                                  { display:block !important; }


.align-center                             {  text-align: center;}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
